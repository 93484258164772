// All translations have been moved to separate files:
// - aboutTranslations.js
// - contactTranslations.js
// - educationTranslations.js
// - headerTranslations.js
// - homeTranslations.js
// - projectsTranslations.js
// - skillsTranslations.js
// - socialCardsTranslations.js
// - testimonialsTranslations.js

export const staticData = {};
